@import 'styles/includes.scss';
.FeaturedArticles {
    $root: &;
    font-family: $fontBrand;
    position: relative;
    overflow: hidden;
    color: $colorWhite;
    padding-bottom: 3.4rem;
    padding-top: 3rem;

    &::after {
        content: '';
        position: absolute;
        background-color: $colorBlack800;
        width: 100%;
        height: 100%;
        bottom: 0;
    }

    &--TopPosition {
        color: $colorBlack;

        &::after {
            content: '';
            position: absolute;
            background-color: $colorBlack800;
            width: 100%;
            height: 60%;
            bottom: 0;
        }
    }

    &--BottomPosition {
        color: $colorWhite;

        &::before {
            content: '';
            position: absolute;
            height: 31rem;
            background-color: $colorBlack800;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 0;

            @include media(ml) {
                height: 40.17rem;
            }

        }

        &::after {
            content: ' ';
            left: 0;
            width: 0;
            height: 0;
            position: absolute;
            top: calc(31.2rem - 143px);
            border-bottom: 143px solid $colorWhiteBackground;
            border-right: 143px solid transparent;
            z-index: 0;

            @include media(ml) {
                top: calc(40.2rem - 250px);
                border-bottom-width: 250px;
                border-right-width: 250px;
            }
        }
    }






    @include media(m) {
        padding-bottom: 8.3rem;
        padding-top: 4.8rem;
    }


    &__Button {
        margin-top: 24px;
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1;
    }

    &__ContentContainer {
        @extend %container;

        @include media(m) {
            position: relative;
            z-index: 1;
            transform: translateY(120px);
            opacity: 0;
            transition: all 0.5s ease-out;
            transition-delay: $transitionDelayMedium;

            &--isVisible {
                opacity: 1;
                transform: translateY(0px);
            }
        }
    }

    &__TitleContainer {
        display: flex;
        flex-flow: column;
        justify-content: space-between;

        @include media(m) {
            flex-flow: row;
        }
    }

    &__Title {
        #{$root}--OutOfView & {
            background-color: $colorBlack800;
        }

        font-weight: bold;
        font-size: 2.6rem;
        line-height: 1.32;
        display: block;
        letter-spacing: 0.4px;
        margin-bottom: 1rem;
        z-index: 1;

        @include media(m) {
            font-size: 3.5rem;
            line-height: 1.31;
            letter-spacing: 0.8px;
        }
    }

    &__PreambleContainer {
        // margin-bottom: 0;
        position: relative;
        z-index: 1;
    }

    &__Preamble {
        #{$root}--OutOfView & {
            background-color: $colorBlack800;
        }

        font-size: 1.6rem;
        width: 100%;
        line-height: 1.65;
        margin-bottom: 2.4rem;

        @include media(m) {
            width: 50%;
            padding-right: $spacingXl;
        }

        &--HalfBackground {
            color: $colorBlack;
        }
    }

    &__Cards {
        position: relative;
        display: flex;
        flex-direction: row;
        margin-right: 0;
        padding-right: $spacingXl;
        padding-left: 16px;
        width: 100%;

        @include media(s) {
            padding-left: calc((100% - #{map-get($maxwidths, s)}) / 2);
            max-width: calc(
                #{map-get($maxwidths, s)} + calc((100% - #{map-get(
                                    $maxwidths,
                                    s
                                )}))
            );
        }

        @include media(m) {
            padding-left: calc((100% - #{map-get($maxwidths, m)}) / 2);
            max-width: calc(
                #{map-get($maxwidths, m)} + calc((100% - #{map-get(
                                    $maxwidths,
                                    m
                                )}))
            );
        }

        @include media(ml) {
            padding-left: calc((100% - #{map-get($maxwidths, ml)}) / 2);
            padding-right: calc((100% - #{map-get($maxwidths, ml)}) / 2);
            max-width: calc(
                #{map-get($maxwidths, ml)} + calc((
                                100% - #{map-get($maxwidths, l)}
                            ) / 2)
            );
        }

        @include media(l) {
            overflow: visible;
            padding-left: calc((100% - #{map-get($maxwidths, l)}) / 2);
            padding-right: calc((100% - #{map-get($maxwidths, l)}) / 2);
            max-width: calc(
                #{map-get($maxwidths, l)} + calc((
                                100% - #{map-get($maxwidths, l)}
                            ) / 2)
            );
        }

        @include media(xl) {
            padding-left: calc((100% - #{map-get($maxwidths, xl)}) / 2);
            padding-right: calc((100% - #{map-get($maxwidths, xl)}) / 2);
            max-width: calc(
                #{map-get($maxwidths, xl)} + calc((
                                100% - #{map-get($maxwidths, xl)}
                            ) / 2)
            );
        }
    }

    &__FeaturedCards {
        display: none;

        @include media(ml) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__Card {
        margin-right: 2.4rem;
        flex-basis: calc(25% - 1.2rem);

        &:first-of-type {
            @include media(ml) {
                flex-basis: calc(50% - 1.2rem);
            }
        }
        &:last-of-type {
            margin-right: 0;
        }

        &--Half {
            @include media(ml) {
                flex-basis: calc(50% - 1.2rem);
            }
        }
        &--Quarter {
            @include media(ml) {
                flex-basis: calc(25% - 1.2rem);
            }
        }
    }

    &__SliderComponent {
        margin-top: 0;
        & :global {
            .Slide {
                min-width: 32.8rem;
            }
        }
    }

    &__SliderComponent__Slide {
        flex-basis: 34.3rem;
    }

    &__Slider {
        display: flex;

        @include media(ml) {
            display: none;
        }
    }
}
